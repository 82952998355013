import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-100 p-p-3 p-d-flex p-jc-between" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "w-100 p-p-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfilePanel = _resolveComponent("ProfilePanel")!
  const _component_CategoryPanel = _resolveComponent("CategoryPanel")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Sidebar, {
      ref: "sidebarCat",
      class: "p-p-0",
      visible: _ctx.visibleLeft,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visibleLeft) = $event)),
      position: "right",
      showCloseIcon: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("img", {
            alt: "logo",
            src: _ctx.logo,
            height: "40",
            class: "p-mr-2",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.getToHomePage && _ctx.getToHomePage(...args)))
          }, null, 8, _hoisted_2),
          _createElementVNode("i", {
            class: "pi pi-times p-my-auto",
            style: {"fontsize":"2rem"},
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setSidebarVisible(false)))
          })
        ]),
        (_ctx.platform !== 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_ProfilePanel, { onSetSidebarVisible: _ctx.setSidebarVisible }, null, 8, ["onSetSidebarVisible"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createVNode(_component_CategoryPanel, { onSetSidebarVisible: _ctx.setSidebarVisible }, null, 8, ["onSetSidebarVisible"])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}