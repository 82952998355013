import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cb3aa736"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "p-d-flex m-3 w-100"
}
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "p-ml-4 p-my-auto" }
const _hoisted_4 = {
  key: 0,
  class: "p-text-bold"
}
const _hoisted_5 = {
  key: 1,
  class: "p-text-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.checkProfile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.userProfile.profilePictureUrl || _ctx.userSocial.profilePictureUrl)
              ? (_openBlock(), _createBlock(_component_Avatar, {
                  key: 0,
                  image: 
            _ctx.userProfile.profilePictureUrl
              ? _ctx.userProfile.profilePictureUrl
              : _ctx.userSocial.profilePictureUrl
          ,
                  size: "xlarge",
                  shape: "circle"
                }, null, 8, ["image"]))
              : (_openBlock(), _createBlock(_component_Avatar, {
                  key: 1,
                  icon: "pi pi-user",
                  class: "p-mr-2",
                  size: "xlarge",
                  shape: "circle"
                }))
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              (_ctx.userProfile.socialName || _ctx.userSocial.socialName)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "สวัสดี, " + _toDisplayString(_ctx.userProfile.socialName
                ? _ctx.userProfile.socialName
                : _ctx.userSocial.socialName), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, "สวัสดี"))
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("u", {
                class: "text-history pointer",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.getToOrderHistoryPage && _ctx.getToOrderHistoryPage(...args)))
              }, "ประวัติการสั่งซื้อ")
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}