
import { defineComponent, ref, computed, watch, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import SidebarCat from "@/components/sidebar/SidebarCategory.vue";
import { useStore } from "@/store";
import { CategoryActionTypes } from "@/store/modules/category/action-types";
import { CateGoryListStorePayload } from "@/store/modules/category/actions";

export default defineComponent({
  components: {
    SidebarCat,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const sidebarCat = ref<InstanceType<typeof SidebarCat>>();
    const isOrder = ref<boolean>(false);
    const store = useStore();

    const onSerachProduct = () => {
      router.push(
        `/${route.params.initialId}/search?sessionId=${route.query.sessionId}`
      );
    };

    const getToHomePage = () => {
      router.push(
        `/${route.params.initialId}/product?sessionId=${route.query.sessionId}`
      );
    };

    const onCheckoutCart = () => {
      router.push(
        `/${route.params.initialId}/checkout?sessionId=${route.query.sessionId}`
      );
    };

    const setSidebarVisible = () => {
      sidebarCat.value?.setSidebarVisible(true);
    };

    const onCheckRoute = (name?: string) => {
      if (name == "Transaction") {
        isOrder.value = true;
      } else {
        isOrder.value = false;
      }
    };

    const onCheckPlatformBeforeRoute = () => {
      if (store.getters.getPlatform == 1) {
        router.push(
          `/${store.getters.getBrand.lineOAId}/product?sessionId=${route.query.sessionId}`
        );
      } else if (store.getters.getPlatform == 2) {
        router.push(
          `/${store.getters.getBrand.pageId}/product?sessionId=${route.query.sessionId}`
        );
      } else if (store.getters.getPlatform == 5) {
        router.push(
          `/${store.getters.getBrand.igId}/product?sessionId=${route.query.sessionId}`
        );
      } else {
        router.push(`/${store.getters.getBrand.lineOAId}/product`);
      }

      const payload = {
        brandId: store.getters.getBrand.brandId,
        category: "",
      } as CateGoryListStorePayload;

      store.dispatch(CategoryActionTypes.SET_CATEGORY_LIST, payload);
    };

    onMounted(() => {
      onCheckRoute(route.name?.toString());
    });

    watch(route, (route) => {
      onCheckRoute(route.name?.toString());
    });

    return {
      logo: computed(() => store.getters.getBrand.logo),
      color: computed(() => store.getters.getBrand.colorThemeMain),
      onSerachProduct,
      getToHomePage,
      setSidebarVisible,
      onCheckoutCart,
      sidebarCat,
      isOrder,
      quantity: computed(() => store.getters.getQuantity),
      onCheckPlatformBeforeRoute,
    };
  },
});
