import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tree = _resolveComponent("Tree")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Tree, {
      value: _ctx.categories,
      onNodeSelect: _ctx.onNodeSelect,
      selectionMode: "single",
      metaKeySelection: false
    }, null, 8, ["value", "onNodeSelect"])
  ]))
}