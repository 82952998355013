
import { useStore } from "@/store";
import { computed, defineComponent } from "vue";

export default defineComponent({
  setup() {
    const store = useStore();
    return {
      componentLoading: computed(() => store.getters.getComponentLoading),
    };
  },
});
