
import { computed, defineComponent, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "@/store";

import UserService from "../../service/user-service/UserService";

export default defineComponent({
  emits: ["setSidebarVisible"],
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    let userSocial = reactive({});
    const checkProfile = ref<boolean>(false);
    const getToOrderHistoryPage = () => {
      emit("setSidebarVisible", false);
      router.push(
        `/${route.params.initialId}/order/history?sessionId=${route.query.sessionId}`
      );
    };

    return {
      userProfile: computed(() => store.getters.getUser),
      store,
      userSocial,
      getToOrderHistoryPage,
      checkProfile,
    };
  },
  async mounted() {
    if (!this.userProfile.socialName) {
      const service = new UserService();
      const res = await service.getUserSocial({
        SocialReferenceId: this.store.getters.getUser.socialReferenceId,
        BrandId: 1,
        TypeId: this.store.getters.getPlatform,
      });
      this.userSocial = res;
      this.checkProfile = true;
    } else {
      this.checkProfile = true;
    }
  },
});
