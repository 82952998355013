
import { computed, defineComponent, onMounted } from "vue";
import { Category, CategoryDisplay } from "@/types/category/Category.interface";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";

export default defineComponent({
  emits: ["setSidebarVisible"],
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const defaultCat = [
      {
        key: 0,
        label: "ทั้งหมด",
        data: "",
        icon: "",
        children: [],
      },
    ] as CategoryDisplay[];
    const categories = computed(() =>
      defaultCat.concat(mapCategoryList(store.getters.getCategoryList))
    );

    const mapCategoryList = (categoryList: Category[]): CategoryDisplay[] => {
      return categoryList.map((element: Category) => {
        const children = mapCategoryList(element.categoryList);
        return {
          key: element.id,
          label: element.name,
          data: element.urlKey,
          icon: "",
          children: children,
        };
      });
    };

    const onNodeSelect = (node: any) => {
      const redirectUil = `/${route.params.initialId}/product/${node.data}?sessionId=${route.query.sessionId}`;

      if (route.params.category !== node.data) {
        router.push(redirectUil);
      }
      emit("setSidebarVisible", false);
    };

    return {
      categories,
      onNodeSelect,
    };
  },
});
