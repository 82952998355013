
import { defineComponent, ref, computed, watch } from "vue";
import ProfilePanel from "@/components/sidebar/ProfilePanel.vue";
import CategoryPanel from "@/components/sidebar/CategoryPanel.vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    ProfilePanel,
    CategoryPanel,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const visibleLeft = ref<boolean>(false);

    const setSidebarVisible = (value: boolean) => {
      visibleLeft.value = value;
    };
    const getToHomePage = () => {
      router.push(
        `/${route.params.initialId}/product?sessionId=${route.query.sessionId}`
      );
    };

    return {
      visibleLeft,
      logo: computed(() => store.getters.getBrand.logo),
      platform: computed(() => store.getters.getPlatform),
      setSidebarVisible,
      getToHomePage,
    };
  },
});
