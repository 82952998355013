
import { defineComponent, computed, watch } from "vue";
import Header from "../header/Header.vue";
import ModalLoading from "@/components/modal/ModalLoading.vue";
import { useStore } from "@/store";
import { useRoute } from "vue-router";
import { GlobalActionTypes } from "@/store/modules/global/action-types";
import { CategoryActionTypes } from "@/store/modules/category/action-types";
import { CartActionTypes } from "@/store/modules/cart/action-types";

export default defineComponent({
  components: {
    Header,
    ModalLoading,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const getAppData = async () => {
      await store.dispatch(GlobalActionTypes.GET_APP_DATA, false);

      // regis webview on fb
      if (store.getters.getPlatform == 2) {
        registerFacebookWebview();
      }

      // ถ้าเปิดหน้าแรกเป็นหน้ารายละเอียดสินค้าแล้วไม่เปลี่ยนหน้า set true เพื่อ ปิด browser หลังเพิ่มสินค้าใส่ตะกร้า
      if (route.name == "Product Detail") {
        store.dispatch(GlobalActionTypes.SET_IS_IN_PRODUCT_DETAIL, true);
      }
    };

    const registerFacebookWebview = () => {
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.setAttribute(
          "src",
          "//connect.facebook.net/en_US/messenger.Extensions.js"
        );
        fjs.parentNode?.insertBefore(js, fjs);
        console.log("registerFacebookWebview");
      })(document, "script", "Messenger");
    };

    watch(route, (route) => {
      const payload = {
        category: route.params.category?.toString(),
        categoryList: store.getters.getCategoryList,
      };
      const cartPayload = {
        branchId: store.getters.getBrand.branchId,
        socialReferenceId: store.getters.getUser.socialReferenceId,
      };

      store.dispatch(CategoryActionTypes.SET_CATEGORY_CONDITION, payload);
      store.dispatch(CartActionTypes.GET_CART, cartPayload);

      // ถ้าเปิดหน้าแรกเป็นหน้ารายละเอียดสินค้าแล้วเปลี่ยนหน้า set false เพื่อไม่ปิด browser หลังเพิ่มสินค้าใส่ตะกร้า
      if (route.name !== "Product Detail") {
        store.dispatch(GlobalActionTypes.SET_IS_IN_PRODUCT_DETAIL, false);
      }
    });

    return {
      isLoading: computed(() => store.getters.getLoading),
      brandId: computed(() => store.getters.getBrand.brandId),
      componentLoading: computed(() => store.getters.getComponentLoading),
      platform: computed(() => store.getters.getPlatform),
      getAppData,
      registerFacebookWebview,
    };
  },
  async beforeCreate() {
    await this.getAppData();
  },
});
